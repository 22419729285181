
import {defineComponent, h, reactive, ref, resolveComponent, UnwrapRef} from 'vue';
import {message} from "ant-design-vue";
import Dict from "@/request/Dict";

export default defineComponent({
    setup() {
        const path = ref(Dict.path);

        const table = ref()

        const formRef = ref();

        const params = reactive({
            companyId: ''
        })

        const onCompanyChange = (data: any) => {
            params.companyId = data.item.id
            formData.companyId = params.companyId
            table.value ? table.value.flush(1) : null
        }

        const formData: UnwrapRef<any> = reactive({
            id: null,
            name: '',
            parentId: '0',
            companyId: '',
            category: '',
        });

        const visible = ref<boolean>(false);

        const showDrawer = () => {
            formRef.value ? formRef.value.resetFields() : '';
            visible.value = true;
            formData.id = null;
            formData.name = '';
            formData.category = '';
            formData.companyId = params.companyId;
            formData.parentId = '0';
        };

        const add = (data: any) => {
            if (data) {
                visible.value = true;
                formData.category = data.category
            }
        }

        const edit = (data: any) => {
            visible.value = true;
            formData.id = data.id;
            formData.name = data.name;
            formData.category = data.category
        }

        const onClose = () => {
            formRef.value.resetFields();
            visible.value = false;
        };

        const onSubmit = () => {
            formRef.value
                .validate()
                .then(() => {
                    Dict.save(formData).then((body: any) => {
                        if (body.code === 0) {
                            visible.value = false;
                            formRef.value.resetFields();
                            message.info("保存成功！")
                            table.value.flush(1)
                        } else {
                            message.error(body.msg);
                        }
                    });
                })
        }

        return {
            showCompanyList: ref<boolean>(false),
            path,
            table,
            formRef,
            visible,
            onSubmit,
            showDrawer,
            onClose,
            formData,
            add,
            edit,
            params,
            onCompanyChange,
            actions: ref<Actions[]>([
                    {
                        name: '新增',
                        event: 'add'
                    },
                    {
                        name: '编辑',
                        event: 'edit'
                    },
                    {
                        name: '删除',
                        event: 'del'
                    }
                ]
            ),
            rules: {
                name: [{required: true, message: '请输入名称'}],
                category: [{required: true, message: '请输入类别'}],
            },
            columns: ref([
                {
                    dataIndex: 'name',
                    title: '字典名称'
                },
                {
                    dataIndex: 'category',
                    title: '类别'
                },
                {
                    dataIndex: 'parentName',
                    title: '父级'
                },
                {
                    dataIndex: 'createTime',
                    title: '创建时间'
                },
                {
                    dataIndex: 'updateTime',
                    title: '修改时间'
                },
                {
                    title: '操作',
                    align: 'center',
                    key: 'action',
                    fixed: 'right',
                    width: 150,
                    customRender: ({record}: any) => {
                        return h(
                            'div',
                            {},
                            [
                                h('a', {
                                        style: 'margin-right: 10px;' + (record.parentId != '0' ? 'pointer-events: none;filter: alpha(opacity=50); -moz-opacity: 0.5; opacity: 0.5;' : ''),
                                        onClick() {
                                            visible.value = true;
                                            formData.parentId = record.id
                                            formData.category = record.category
                                        }
                                    },
                                    '新增'),
                                h('a', {
                                    style: 'margin-right: 10px;',
                                    onClick() {
                                        visible.value = true;
                                        formData.id = record.id;
                                        formData.name = record.name
                                        formData.category = record.category
                                    }
                                }, '编辑'),
                                h('a', {
                                }, h(resolveComponent('a-popconfirm'), {
                                    title: "确定删除吗?",
                                    okText: "确定",
                                    cancelText: "取消",
                                    onConfirm() {
                                        Dict.deleteById(record.id).then((body: any) => {
                                            if (body.code === 0) {
                                                message.info('删除成功！')
                                                table.value.flush(1)
                                            }
                                        })
                                    }
                                }, '删除'))
                            ]
                        )
                    }
                }
            ]),
        }
    }
})
