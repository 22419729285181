import {Request} from "@/Request";

class Dict extends Request {
    
    public path: string = '/dict';

    getByCategory = (data: any) => {
        return this.formPost(`${this.path}/getByCategory`, data)
    }

    deleteById = (id: string) => {
        return this.delete(`${this.path}/${id}`);
    }

}

export default new Dict();